import { render, staticRenderFns } from "./AddEditAnotacoes.vue?vue&type=template&id=e8f36742&scoped=true"
import script from "./AddEditAnotacoes.vue?vue&type=script&lang=js"
export * from "./AddEditAnotacoes.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e8f36742",
  null
  
)

export default component.exports