<template>
  <v-dialog
    v-model="dialogAddEditAnotacoes"
    @click:outside="$emit('update:dialogAddEditAnotacoes', false)"
    max-width="800"
    scrollable
  >
    <v-card height="100%" class="mobile-size">
      <v-card-title class="primary white--text pa-4">
        {{ edit ? "Editar" : "Adicionar" }} Anotação
        <v-spacer></v-spacer>
        <v-btn dark icon @click="$emit('update:dialogAddEditAnotacoes', false)"
          ><v-icon>mdi-close</v-icon></v-btn
        >
      </v-card-title>
      <v-divider></v-divider>
      <v-form ref="form" v-model="valirdForm">
        <v-card-text class="pa-0">
          <v-textarea
            v-model="anotacao.obs"
            placeholder="Anotações"
            :rows="12"
            solo
            flat
            class="mobile-size"
            no-resize
            required
            :rules="formRules"
          ></v-textarea>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-combobox
            v-model="assuntoSelected"
            :items="assuntos"
            class="ma-0 mobile-size"
            height="40px"
            style="max-width: 250px"
            label="assunto"
            item-text="descricao"
            item-value="id"
            :item-disabled="checkIsItemDisabled"
            dense
            outlined
            :loading="loading"
            hide-details
            clearable
            required
            :rules="formRules"
          ></v-combobox>
          <v-spacer></v-spacer>
          <v-btn
            :loading="loading"
            @click="edit ? updateOb() : postObs()"
            color="xbColor"
            class="white--text mobile-size"
          >
            <v-icon left>mdi-plus</v-icon> {{ edit ? "Salvar" : "Adicionar" }}
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import { inputRequired } from "@/filters/index.js";
import { postAnotacao, putAnotacao } from "@/api/staff/leeds_anotacoes.js";
import { mapState } from "vuex";
import { getAssuntos } from "@/api/staff/leeds_assuntos.js";

export default {
  name: "AddEditAnotacoes",

  props: {
    dialogAddEditAnotacoes: {
      type: Boolean,
      required: true,
    },
    edit: {
      type: Boolean,
      default: false,
    },
    usuarioLeed: {
      type: [Number, String],
      required: true,
    },
    item: {
      type: Object,
    },
  },

  data() {
    return {
      anotacao: {},
      assuntos: [],
      loading: true,
      assuntoSelected: null,
      valirdForm: true,
      formRules: [inputRequired],
    };
  },

  computed: {
    ...mapState("Usuario", {
      user_id: (state) => state.usuario.id,
    }),
  },

  watch: {
    assuntoSelected() {
      if (this.assuntoSelected) {
        this.anotacao.assunto_id = this.assuntoSelected.id;
      }
    },
  },

  methods: {
    fetchAssuntos() {
      return getAssuntos()
        .then((response) => {
          this.assuntos = response;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    checkIsItemDisabled(item) {
      return item.status === 0;
    },
    postObs() {
      if (this.$refs.form.validate()) {
        this.loading = true;
        this.anotacao.user_id = this.usuarioLeed;
        this.anotacao.created_id = this.user_id;
        postAnotacao(this.anotacao)
          .then((response) => {
            if (response.status == 201) {
              this.$toast.success("Anotação criada com sucesso!");
              this.loading = false;
              this.$emit("fetch-anotacao");
              this.$emit("update:dialogAddEditAnotacoes", false);
            }
          })
          .catch(() => {
            this.loading = false;
          });
      }
    },
    updateOb() {
      if (this.$refs.form.validate()) {
        this.loading = true;
        let obs = {};
        obs.id = this.anotacao.id;
        obs.user_id = this.usuarioLeed;
        obs.created_id = this.user_id;
        obs.obs = this.anotacao.obs;
        obs.assunto_id = this.anotacao.assunto_id;

        putAnotacao(obs.id, obs)
          .then((response) => {
            if (response.status === 200) {
              this.$toast.success("Anotação atualizado com sucesso!");
              this.$emit("fetch-anotacao");
              this.$emit("update:dialogAddEditAnotacoes", false);
              this.$Progress.finish();
              this.loading = false;
            }
          })
          .catch(() => {
            this.loading = false;
          });
      }
    },
  },

  async mounted() {
    this.loading = true;
    await this.fetchAssuntos();
    if (this.edit) {
      this.anotacao = { ...this.item };
      this.assuntoSelected = this.assuntos.find(
        (assunto) => assunto.id == this.anotacao.assunto_id
      );
      console.log(this.assuntoSelected);
    }
    this.loading = false;
  },
};
</script>

<style lang="scss" scoped></style>
